<script setup lang="ts">
import {
  LayoutDashboardIcon,
  RoadIcon,
  Category2Icon,
  CreditCardIcon,
  TemplateIcon,
  RotateRectangleIcon,
  UsersIcon
} from 'vue-tabler-icons'

export interface menu {
  header?: string
  title?: string
  icon?: any
  to?: string
  chip?: string
  chipBgColor?: string
  chipColor?: string
  chipVariant?: string
  chipIcon?: string
  children?: menu[]
  disabled?: boolean
  type?: string
  subCaption?: string
}

const authStore = useAuthStore()
const customizer = useCustomizerStore()
const menuItems = shallowRef<menu[]>([])
const roles: string[] = authStore.roles

const base: menu[] = [
  { header: 'Home' },
  {
    title: 'Dashboard',
    icon: LayoutDashboardIcon,
    to: '/'
  }
]

const tourAdmin: menu[] = [
  { header: 'Digitale Touren' },
  {
    title: 'Touren',
    icon: RoadIcon,
    to: '/tours'
  },
  {
    title: 'Kategorien',
    icon: Category2Icon,
    to: '/tours/categories'
  }
]

const walletAdmin: menu[] = [
  { header: 'Wallet' },
  {
    title: 'Karten',
    icon: CreditCardIcon,
    to: '/wallet/cards'
  },
  {
    title: 'Kartentypen',
    icon: CreditCardIcon,
    to: '/wallet/types'
  },
  {
    title: 'Templates',
    icon: TemplateIcon,
    to: '/wallet/templates'
  }
]

const jobAdmin: menu[] = [
  { header: 'Jobs' },
  {
    title: 'Cron Jobs',
    icon: RotateRectangleIcon,
    to: '/jobs'
  }
]

// const pushAdmin: menu[] = [
//   { header: 'Push Nachrichten' },
//   {
//     title: 'Versenden',
//     icon: SendIcon,
//     to: '/push/send'
//   },
//   {
//     title: 'Audienzen',
//     icon: UsersGroupIcon,
//     to: '/push/audiences'
//   },
//   {
//     title: 'Historie',
//     icon: HistoryIcon,
//     to: '/push'
//   }
// ]

const userAdmin: menu[] = [
  { header: 'Benutzerverwaltung' },
  {
    title: 'Benutzer',
    icon: UsersIcon,
    to: '/users'
  }
]

menuItems.value = base

if (roles.includes('tour-admin')) {
  menuItems.value = [...menuItems.value, ...tourAdmin]
}

if (roles.includes('wallet-admin')) {
  menuItems.value = [...menuItems.value, ...walletAdmin]
}

if (roles.includes('job-admin')) {
  menuItems.value = [...menuItems.value, ...jobAdmin]
}

// if (roles.includes('push-admin')) {
//   menuItems.value = [...menuItems.value, ...pushAdmin]
// }

if (roles.includes('user-admin')) {
  menuItems.value = [...menuItems.value, ...userAdmin]
}
</script>
<script lang="ts">
export default {
  name: 'VerticalSidebar'
}
</script>

<template>
  <v-navigation-drawer
    v-model="customizer.Sidebar_drawer"
    left
    elevation="0"
    rail-width="75"
    :mobile-breakpoint="960"
    app
    class="leftSidebar"
    :rail="customizer.mini_sidebar"
    expand-on-hover
    width="270"
  >
    <!---Logo part -->
    <v-locale-provider v-if="customizer.setRTLLayout" rtl>
      <div class="pa-5">
        <Logo />
      </div>
    </v-locale-provider>
    <v-locale-provider v-else>
      <div class="pa-5">
        <Logo />
      </div>
    </v-locale-provider>

    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list class="pa-6" density="compact">
        <!---Menu Loop -->
        <template v-for="(item, i) in menuItems">
          <!---Item Sub Header -->
          <NavGroup v-if="item.header" :key="item.title" :item="item" />
          <!---If Has Child -->
          <NavCollapse
            v-else-if="item.children"
            :key="i"
            class="leftPadding"
            :item="item"
            :level="0"
          />
          <!---Single Item-->
          <NavItem v-else :key="i * 100" :item="item" class="leftPadding" />
          <!---End Single Item-->
        </template>
      </v-list>
      <div class="pa-6 userbottom">
        <Profile />
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
