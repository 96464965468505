<script setup lang="ts">
// Icon Imports
const customizer = useCustomizerStore()
const priority = ref(customizer.setHorizontalLayout ? 0 : 0)

watch(priority, newPriority => {
  // yes, console.log() is a side effect
  priority.value = newPriority
})
</script>
<script lang="ts">
export default {
  name: 'VerticalHeader'
}
</script>

<template>
  <v-app-bar elevation="0" :priority="priority" height="70" class="">
    <v-btn
      class="hidden-md-and-down custom-hover-primary"
      icon
      color="primary"
      variant="text"
      @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)"
    >
      <Menu2Icon size="20" stroke-width="1.5" />
    </v-btn>
    <v-btn
      class="hidden-lg-and-up ms-3"
      icon
      variant="flat"
      size="small"
      @click.stop="customizer.SET_SIDEBAR_DRAWER"
    >
      <Menu2Icon size="20" stroke-width="1.5" />
    </v-btn>

    <!-- ---------------------------------------------- -->
    <!-- Search part -->
    <!-- ---------------------------------------------- -->
    <v-sheet class="">
      <Searchbar />
    </v-sheet>

    <v-spacer />

    <!-- ---------------------------------------------- -->
    <!-- User Profile -->
    <!-- ---------------------------------------------- -->
    <div class="ml-3">
      <ProfileDD />
    </div>
  </v-app-bar>
</template>
