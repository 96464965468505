<script setup>
import Icon from '../Icon.vue'

const properties = defineProps({
  item: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
})
</script>
<script>
export default {
  name: 'NavCollapse'
}
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!---Item Childern -->
  <!-- ---------------------------------------------- -->
  <v-list-group no-action>
    <!-- ---------------------------------------------- -->
    <!---Dropdown  -->
    <!-- ---------------------------------------------- -->
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        :value="properties.item.title"
        rounded
        class="mb-1"
        color="primary"
      >
        <!---Icon  -->
        <template #prepend>
          <Icon :item="properties.item.icon" :level="properties.level" />
        </template>
        <!---Title  -->
        <v-list-item-title class="mr-auto">{{ $t(properties.item.title) }}</v-list-item-title>
        <!---If Caption-->
        <v-list-item-subtitle
          v-if="properties.item.subCaption"
          class="text-caption mt-n1 hide-menu"
        >
          {{ properties.item.subCaption }}
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <!-- ---------------------------------------------- -->
    <!---Sub Item-->
    <!-- ---------------------------------------------- -->
    <template v-if="properties.item.children">
      <template v-for="(subitem, i) in properties.item.children" :key="i">
        <LcFullVerticalSidebarNavCollapse
          v-if="subitem.children"
          :item="subitem"
          :level="properties.level + 1"
        />
        <LcFullVerticalSidebarNavItem
          v-else
          :item="subitem"
          :level="properties.level + 1"
        ></LcFullVerticalSidebarNavItem>
      </template>
    </template>
  </v-list-group>

  <!-- ---------------------------------------------- -->
  <!---End Item Sub Header -->
  <!-- ---------------------------------------------- -->
</template>
