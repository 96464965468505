<script setup lang="ts">
const customizer = useCustomizerStore()
const title = ref('OSCA Bochum Dashboard')
useHead({
  meta: [{ content: title }],
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - OSCA Bochum Dashboard` : 'OSCA Bochum Dashboard'
  }
})

defineComponent({
  name: 'DefaultLayout'
})
</script>

<template>
  <v-app
    :theme="customizer.actTheme"
    :class="[
      customizer.actTheme,
      customizer.mini_sidebar ? 'mini-sidebar' : '',
      customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
      customizer.setBorderCard ? 'cardBordered' : ''
    ]"
  >
    <VerticalSidebar v-if="!customizer.setHorizontalLayout" />
    <VerticalHeader v-if="!customizer.setHorizontalLayout" />
    <v-main>
      <v-container fluid class="page-wrapper pb-sm-15 pb-10">
        <div :class="customizer.boxed ? 'maxWidth' : ''">
          <NuxtPage />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
