<script setup>
const properties = defineProps({
  item: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
})

defineComponent({
  name: 'IconComponent'
})
</script>

<template>
  <template v-if="properties.level > 0">
    <component :is="properties.item" size="14" stroke-width="1.5" class="iconClass"></component>
  </template>
  <template v-else>
    <component :is="properties.item" size="20" stroke-width="1.5" class="iconClass"></component>
  </template>
</template>
