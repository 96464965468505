<script setup lang="ts">
import { MailIcon } from 'vue-tabler-icons'
import { Md5 } from 'ts-md5'

const authStore = useAuthStore()
const user = ref(authStore.user)
const email = ref(user.value.get('email') || '')
const firstname = ref(user.value.get('firstname') || '')
const lastname = ref(user.value.get('lastname') || '')
const role = ref(user.value.get('role') || '')
const fullname = firstname.value && lastname.value ? ` ${firstname.value} ${lastname.value}` : ''
const profileImageUrl = ref(
  user.value.get('imageUrl') ||
    `https://www.gravatar.com/avatar/${Md5.hashStr(email.value)}?s=80&d=retro&r=g`
)

const profileDD = [
  {
    title: 'Mein Profil',
    subtitle: 'Mein Profil bearbeiten',
    avatar: '/images/svgs/icon-account.svg',
    href: '/profile'
  }
]

function logout() {
  authStore.logout().then(() => {
    navigateTo('/auth/login')
  })
}
</script>

<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn class="custom-hover-primary" variant="text" v-bind="props" icon>
        <v-avatar size="35">
          <v-img :src="profileImageUrl" width="35" alt="{{ user.firstname }} {{ user.lastname }}" />
        </v-avatar>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="400" elevation="10">
      <div class="px-8 pt-6">
        <h6 class="text-h5 font-weight-medium">Profil</h6>
        <div class="d-flex align-center mt-4 pb-6">
          <v-avatar size="60">
            <v-img :src="profileImageUrl" width="60" />
          </v-avatar>
          <div class="ml-3">
            <h6 class="text-h6 mb-n1">{{ fullname }}</h6>
            <span class="text-subtitle-1 font-weight-regular textSecondary">{{ role }}</span>
            <div class="d-flex align-center mt-1">
              <MailIcon size="18" stroke-width="1.5" />
              <span class="text-subtitle-1 font-weight-regular textSecondary ml-2">{{
                email
              }}</span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <perfect-scrollbar style="max-height: 240px">
        <v-list class="py-0 theme-list" lines="two">
          <v-list-item
            v-for="item in profileDD"
            :key="item.title"
            class="py-4 px-8 custom-text-primary"
            :to="item.href"
          >
            <template #prepend>
              <v-avatar size="48" color="lightprimary" rounded="md">
                <v-img :src="item.avatar" width="24" height="24" :alt="item.avatar" />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold mb-2 custom-title">
                {{ item.title }}
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular textSecondary">
              {{ item.subtitle }}
            </p>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>

      <div class="pt-4 pb-6 px-8 text-center">
        <v-btn color="primary" variant="outlined" block @click="logout">Logout</v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>
