<script setup lang="ts">
import { PowerIcon } from 'vue-tabler-icons'
import { Md5 } from 'ts-md5'

const authStore = useAuthStore()
const user = ref(authStore.user)
const email = ref(user.value.get('email') || '')
const firstname = ref(user.value.get('firstname') || '')
const role = ref(user.value.get('role') || '')
const profileImageUrl = ref(
  user.value.get('imageUrl') ||
    `https://www.gravatar.com/avatar/${Md5.hashStr(email.value)}?s=80&d=retro&r=g`
)

function logout() {
  authStore.logout().then(() => {
    navigateTo('/auth/login')
  })
}
defineComponent({
  name: 'VerticalSidebarProfile'
})
</script>

<template>
  <v-sheet rounded="md" color="lightsecondary" class="px-4 py-3 ExtraBox">
    <div class="d-flex align-center hide-menu">
      <v-avatar size="40">
        <img :src="profileImageUrl" alt="user" height="40" />
      </v-avatar>
      <div class="ml-4">
        <h4 class="mb-n1 text-h6 textPrimary">{{ firstname }}</h4>
        <span class="text-subtitle-2 textSecondary">{{ role }}</span>
      </div>
      <div class="ml-auto">
        <v-btn variant="text" icon rounded="md" color="primary" @click="logout">
          <PowerIcon />
          <v-tooltip activator="parent" location="top">Logout</v-tooltip>
        </v-btn>
      </div>
    </div>
  </v-sheet>
</template>

<style scoped lang="scss">
.ExtraBox {
  position: relative;
  overflow: hidden;
}
</style>
